<template>
  <div class="afterSales">
    <div
      class="content"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <div class="search">
        <el-form inline>
          <el-form-item
            :label="lang_data == 1 ? '关键字：' : 'Key words:'"
            style="margin-bottom: 0"
          >
            <el-input
              v-model="keyword"
              size="small"
              :placeholder="
                lang_data == 1 ? '下单码/订单号/患者' : 'Please enter'
              "
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getlist(1)"
              >{{ lang_data == 1 ? "查询" : "Search" }}</el-button
            >
            <a :href="exportlink" download class="linkA"
              ><i class="el-icon-download"></i>
              {{ lang_data == 1 ? "导出" : "Export" }}</a
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="table-con flex-1">
        <el-table
          header-row-class-name="order-table"
          :data="tabledata"
          stripe
          style="width: 100%"
          border
        >
          <el-table-column
            prop="reback_no"
            :label="lang_data == 1 ? '退/返编号' : 'return/remake No.'"
            width="200"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="
                  $router.push({
                    path: '/afterSalesDetails',
                    query: {
                      rebackid: scope.row.new_order_id,
                      id: scope.row.id,
                    },
                  })
                "
                >{{ scope.row.reback_no }}</el-link
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="order_code"
            :label="lang_data == 1 ? '下单码' : 'Order Code'"
            width="105"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="
                  $router.push({
                    path: '/afterSalesDetails',
                    query: {
                      rebackid: scope.row.new_order_id,
                      id: scope.row.id,
                    },
                  })
                "
                >{{ scope.row.new_order_code }}</el-link
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="patient_name"
            width="100px"
            :label="lang_data == 1 ? '患者' : 'Patient'"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="
                  $router.push({
                    path: '/afterSalesDetails',
                    query: {
                      rebackid: scope.row.new_order_id,
                      id: scope.row.id,
                    },
                  })
                "
                >{{ scope.row.patient_name }}</el-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="lang_data == 1 ? '项目' : 'Product'">
            <template slot-scope="scope">
              {{
                scope.row.project_list
                  .map((item) => item.project_name)
                  .join("、")
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="order_no"
            width="160"
            :label="lang_data == 1 ? '原订单号' : 'Original Order No.'"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="$router.push('/orderDetails?id=' + scope.row.order_id)"
                >{{ scope.row.order_no }}</el-link
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="type_show.label"
            width="80px"
            :label="lang_data == 1 ? '类型' : 'Type'"
          >
          </el-table-column>
          <el-table-column
            width="80px"
            :label="lang_data == 1 ? '状态' : 'Status'"
          >
            <template slot-scope="scope">
              <span
                class="status status_f"
                v-if="scope.row.type == 2"
                :class="'status_' + scope.row.rework_status_show.value"
                >{{ scope.row.rework_status_show.label }}</span
              >
              <span
                class="status status_t"
                v-if="scope.row.type == 1"
                :class="'status_' + scope.row.reback_status_show.value"
                >{{ scope.row.reback_status_show.label }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="add_time"
            width="140px"
            :label="lang_data == 1 ? '申请时间' : 'Apply Date'"
          >
            <template slot-scope="scope">
              {{
                $moment(scope.row.add_time * 1000).format("YYYY-MM-DD hh:mm")
              }}
            </template>
          </el-table-column>
          <el-table-column
            width="110px"
            :label="lang_data == 1 ? '操作' : 'Action'"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.reback_status_show.value == 1 ||
                  scope.row.rework_status_show.value == 1
                "
              >
                <!-- 待寄件才显示按钮 -->
                <el-link type="primary" @click="showExpressModal(scope.row)">
                  {{
                    lang_data == 1 ? "上传快递单" : "upload Tracking No."
                  }}</el-link
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="total,jumper,prev, pager, next"
          @current-change="getlist"
          :page-size="page.pageSize"
          :current-page="page.currentPage"
          :total="page.totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <ExpressNumber
      v-if="expressNumberShow"
      :expressNumberShow="expressNumberShow"
      :orderId="[id]"
      @ok-event="okEvent"
      @cancel-event="expressNumberShow = false"
    >
    </ExpressNumber>
  </div>
</template>
<script>
import ExpressNumber from "@/components/expressNumber";
export default {
  name: "afterSales",
  components: { ExpressNumber },
  mounted() {
    this.getlist();
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      page: {
        totalCount: 0,
        pageSize: 10,
        currentPage: 1,
      },
      keyword: "",
      tabledata: [],

      expressShow: false,

      current: null,
      loading: true,
      expressNumberShow: false,
      id: "",
    };
  },
  watch: {
    $route: function () {
      this.getlist();
    },
    project_id: function () {
      this.getlist();
    },
  },
  computed: {
    exportlink() {
      let params = {
        token: localStorage.token,
        export: "export",
      };
      let data = [];
      Object.keys(params).forEach((key) => {
        data.push(`${key}=${params[key]}`);
      });
      return `/apis/orders/order_reback/getList?` + data.join("&");
    },
  },
  methods: {
    okEvent() {
      this.expressNumberShow = false;
      this.getlist(this.page.currentPage);
    },
    getFormatDate(date, type) {
      if (!date) return "";
      let start = !date[0]
        ? ""
        : this.$moment(date[0]).format("YYYY-MM-DD 00:00:00");
      let end = !date[1]
        ? ""
        : this.$moment(date[1]).format("YYYY-MM-DD 23:59:59");

      if (type == "start") {
        return start;
      }
      if (type == "end") {
        if (!date[1]) return "";
        return end;
      }

      return [start, end];
    },
    getlist(current_page) {
      this.loading = true;
      let params = {
        keyword: this.keyword,
        page_size: this.page.pageSize,
        current_page: current_page ? current_page : this.page.currentPage,
      };
      this.$axios.post("/orders/order_reback/getList", params).then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.tabledata = res.data.list;
          this.page.totalCount = res.data.total_count;
          this.page.currentPage = res.data.current_page;
          setTimeout(() => {
            this.loading = false;
          }, 300);
        }
      });
    },
    showExpressModal(item) {
      this.expressNumberShow = true;
      this.id = item.source_order_id;
    },
    ShowExpress(item) {
      this.current = item;
      this.expressShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.afterSales {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  .content {
    width: 1300px;
    height: 100%;
    margin: 0 auto;
  }
  .search {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .linkA {
      width: 71px;
      height: 27px;
      border: 1px solid #dcdfe6;
      display: inline-block;
      border-radius: 3px;
      line-height: 27px;
      text-align: center;
      cursor: pointer;
      vertical-align: middle;
      margin-left: 10px;
      font-size: 12px;
    }
    .linkA:hover {
      border: 1px solid #409eff;
      color: #409eff;
    }
  }
  .ContPadding {
    padding: 0px 84px;
    min-height: 250px;
    max-height: 450px;
    overflow: auto;
  }
  .modatitle {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 110px;
    font-weight: bold;
  }
  .isDesign {
    border-radius: 8px;
    background: #009ae3;
    color: #fff;
    font-size: 12px;
    padding: 0px 3px;
  }

  .bottomCont {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 10px;
  }
  .page {
    text-align: right;
    margin: 20px 0;
  }
}
</style>