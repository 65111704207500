var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"afterSales"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content",attrs:{"element-loading-text":_vm.lang_data == 1 ? '拼命加载中' : 'Loading'}},[_c('div',{staticClass:"search"},[_c('el-form',{attrs:{"inline":""}},[_c('el-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":_vm.lang_data == 1 ? '关键字：' : 'Key words:'}},[_c('el-input',{attrs:{"size":"small","placeholder":_vm.lang_data == 1 ? '下单码/订单号/患者' : 'Please enter',"clearable":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('el-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-search"},on:{"click":function($event){return _vm.getlist(1)}}},[_vm._v(_vm._s(_vm.lang_data == 1 ? "查询" : "Search"))]),_c('a',{staticClass:"linkA",attrs:{"href":_vm.exportlink,"download":""}},[_c('i',{staticClass:"el-icon-download"}),_vm._v(" "+_vm._s(_vm.lang_data == 1 ? "导出" : "Export"))])],1)],1)],1),_c('div',{staticClass:"table-con flex-1"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"header-row-class-name":"order-table","data":_vm.tabledata,"stripe":"","border":""}},[_c('el-table-column',{attrs:{"prop":"reback_no","label":_vm.lang_data == 1 ? '退/返编号' : 'return/remake No.',"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                  path: '/afterSalesDetails',
                  query: {
                    rebackid: scope.row.new_order_id,
                    id: scope.row.id,
                  },
                })}}},[_vm._v(_vm._s(scope.row.reback_no))])]}}])}),_c('el-table-column',{attrs:{"prop":"order_code","label":_vm.lang_data == 1 ? '下单码' : 'Order Code',"width":"105"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                  path: '/afterSalesDetails',
                  query: {
                    rebackid: scope.row.new_order_id,
                    id: scope.row.id,
                  },
                })}}},[_vm._v(_vm._s(scope.row.new_order_code))])]}}])}),_c('el-table-column',{attrs:{"prop":"patient_name","width":"100px","label":_vm.lang_data == 1 ? '患者' : 'Patient'},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                  path: '/afterSalesDetails',
                  query: {
                    rebackid: scope.row.new_order_id,
                    id: scope.row.id,
                  },
                })}}},[_vm._v(_vm._s(scope.row.patient_name))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.lang_data == 1 ? '项目' : 'Product'},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.project_list .map((item) => item.project_name) .join("、"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"order_no","width":"160","label":_vm.lang_data == 1 ? '原订单号' : 'Original Order No.'},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push('/orderDetails?id=' + scope.row.order_id)}}},[_vm._v(_vm._s(scope.row.order_no))])]}}])}),_c('el-table-column',{attrs:{"prop":"type_show.label","width":"80px","label":_vm.lang_data == 1 ? '类型' : 'Type'}}),_c('el-table-column',{attrs:{"width":"80px","label":_vm.lang_data == 1 ? '状态' : 'Status'},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 2)?_c('span',{staticClass:"status status_f",class:'status_' + scope.row.rework_status_show.value},[_vm._v(_vm._s(scope.row.rework_status_show.label))]):_vm._e(),(scope.row.type == 1)?_c('span',{staticClass:"status status_t",class:'status_' + scope.row.reback_status_show.value},[_vm._v(_vm._s(scope.row.reback_status_show.label))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"add_time","width":"140px","label":_vm.lang_data == 1 ? '申请时间' : 'Apply Date'},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$moment(scope.row.add_time * 1000).format("YYYY-MM-DD hh:mm"))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"110px","label":_vm.lang_data == 1 ? '操作' : 'Action'},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.reback_status_show.value == 1 ||
                scope.row.rework_status_show.value == 1
              )?[_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showExpressModal(scope.row)}}},[_vm._v(" "+_vm._s(_vm.lang_data == 1 ? "上传快递单" : "upload Tracking No."))])]:_vm._e()]}}])})],1)],1),_c('div',{staticClass:"page"},[_c('el-pagination',{attrs:{"background":"","layout":"total,jumper,prev, pager, next","page-size":_vm.page.pageSize,"current-page":_vm.page.currentPage,"total":_vm.page.totalCount},on:{"current-change":_vm.getlist}})],1)]),(_vm.expressNumberShow)?_c('ExpressNumber',{attrs:{"expressNumberShow":_vm.expressNumberShow,"orderId":[_vm.id]},on:{"ok-event":_vm.okEvent,"cancel-event":function($event){_vm.expressNumberShow = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }